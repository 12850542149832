const Roles = {
  AGENCY: 'agency',
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
  AGENCYMEMBER: 'agency_member',
}

module.exports = {
  Roles,
}
