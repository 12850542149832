import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import env from "config";

function TestFrame() {
  const { loc_id, name, email, loc_name } = useParams();
  const [text, setText] = useState("Logging In....");
  const [data, setData] = useState(null);

  useEffect(() => {
    const SSOHandler = async () => {
      try {
        const BASE_URL = `${env.API_URL}/v1`;
        const response = await axios.get(
          `${BASE_URL}/sso?loc_id=${loc_id}&name=${name}&email=${email}&loc_name=${loc_name}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    SSOHandler();
  }, [loc_id, name, email]);

  useEffect(() => {
    if (data) {
      const redirectURL = `https://api.sendible.com/api/v2/auth?application_id=b1de52331a0743589150f656f55a3891&username=${data.data.login}&api_key=${data.data.api_key}&source=sso&path=%23measure&redirect_uri=https://reports.maybetech.com/%23auth`;

      console.log(redirectURL);
      window.location.href = redirectURL;
    }
  }, [data]);

  return <>{text}</>;
}

export default TestFrame;
