import { Environment } from '../enums'

const env = {
  API_URL: 'http://localhost:8082/api',
  GHL: {
    CLIENT_ID: '64f8604282165264339a0ccc-lm7nf7n0',
    CLIENT_SECRET: '84249575-8af0-44bf-adb0-d8cf81d6cecb',
    Location_CLIENT_ID: '65bb70ad9ee6116317ba3cdb-ls32qevb',
    Location_CLIENT_SECRET: 'dc5184a2-e7a6-4266-b6fc-a1f1d445c083',
    Location_SCOPE:
      'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write invoices.readonly invoices.write invoices/schedule.write invoices/schedule.readonly invoices/template.readonly invoices/template.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly medias.readonly medias.write opportunities.readonly opportunities.write users.readonly users.write workflows.readonly surveys.readonly',
    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly snapshots.readonly locations.write oauth.write oauth.readonly medias.readonly medias.write',
    APPSCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly snapshots.readonly locations.write oauth.readonly oauth.write calendars.readonly calendars.write calendars/groups.readonly calendars/groups.write calendars/events.readonly calendars/events.write medias.readonly medias.write',
    REDIRECT: {
      LOCAITON: 'https://betachatsuper.maybetech.com/location',
      BUSINESS: 'https://betachatsuper.maybetech.com/business',
      AGENCY: 'https://betachatsuper.maybetech.com/agency',
      AGENCY_APP: 'https://maybe.fixmyonline.com/agency/auth/appconnect',
      SUPER_ADMIN: 'https://agency.levelupmarketplace.io/integrations/ghl/sadmin',
      Location_ASSOCIATE: 'http://localhost:3000/integrations/location',
      SuperAdmin_ASSOCIATE: 'https://agency.levelupmarketplace.io/superadmin/auth',
      ASSOCIATE: 'https://maybe.fixmyonline.com/agency/auth/agency',
    },
  },
}

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = 'https://betachatapi.maybetech.com/api';
  env.Location_ASSOCIATE= 'https://betachatsuper.maybetech.com/integrations/location';
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://apichat.maybetech.com/api'
  env.Location_ASSOCIATE= 'https://betachatsuper.maybetech.com/integrations/location';
}

export default env
