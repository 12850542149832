/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import './styles/style.css'
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Material Dashboard 2 React example components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'
import ghllogo from 'assets/images/maybe.png'

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
// import UpgradeModal from "./UpgradeModal";
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from 'context'
import SidenavItem from './SidenavItem'
import SidenavList from './SidenavList'
import { useUserInfo } from 'context/user'
import { useAgencyInfo } from 'context/agency'
import axios from 'axios'

function Sidenav({ color, brand, brand_data, user_data, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false)
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller
  const location = useLocation()
  const navigate = useNavigate()
  const [agency] = useAgencyInfo()
  const collapseName = location.pathname.split('/')[2]
  const parent_path = location.pathname.split('/')[1]
  const items = location.pathname.split('/').slice(1)
  const itemParentName = items[0]
  const itemName = items[items.length - 1]
  const [user] = useUserInfo()

  console.log(user, 'useruseruseruser')
  let textColor = 'white'

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark'
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit'
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true)
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200)
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav)
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav)
  }, [dispatch, location])

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink
          to={`.${route?.replace('/*', '')}`}
          key={key}
          style={{ textDecoration: 'none' }}
          className={'brand-list-item'}
        >
          <SidenavItem name={name} active={route.replace('/*', '') === location.pathname} nested />
        </NavLink>
      )
    )

    return template
  }

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, icon }) => {
      let returnValue
      if (user.roles.includes('agency_member')) {
        const obj = user?.permissions.find((o) => o.value === key)
        console.log(obj, 'objobj')
        if (obj != undefined) {
          if (collapse) {
            returnValue = (
              <SidenavItem
                key={key}
                color={color}
                name={name}
                active={key === itemParentName ? 'isParent' : false}
                open={openNestedCollapse === key}
                onClick={({ currentTarget }) =>
                  openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
                    ? setOpenNestedCollapse(false)
                    : setOpenNestedCollapse(key)
                }
              >
                {renderNestedCollapse(collapse)}
              </SidenavItem>
            )
          } else {
            returnValue = href ? (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <SidenavItem color={color} name={name} active={key === itemName} />
              </Link>
            ) : (
              <NavLink
                to={`.${route?.replace('/*', '')}`}
                key={key}
                style={{ textDecoration: 'none' }}
                className={'brand-list-item'}
              >
                <SidenavItem
                  brand_data={brand_data}
                  color={color}
                  name={name}
                  icon={icon}
                  active={
                    key === itemName || ('/' + key + '/' + itemName).trim() === location.pathname
                  }
                />
              </NavLink>
            )
          }
        }
      } else {
        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              color={color}
              name={name}
              active={key === itemParentName ? 'isParent' : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          )
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <SidenavItem color={color} name={name} active={key === itemName} />
            </Link>
          ) : (
            <NavLink
              to={`.${route?.replace('/*', '')}`}
              key={key}
              style={{ textDecoration: 'none' }}
              className={'brand-list-item'}
            >
              <SidenavItem
                brand_data={brand_data}
                color={color}
                name={name}
                icon={icon}
                active={
                  key === itemName || ('/' + key + '/' + itemName).trim() === location.pathname
                }
              />
            </NavLink>
          )
        }
      }

      return <SidenavList key={key}>{returnValue}</SidenavList>
    })

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue
      console.log(key, 'keykeykey')

      if (parent_path != 'settings') {
        if (type === 'collapse') {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  brand_data={brand_data}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            )
          } else if (!collapse && route) {
            returnValue = (
              <NavLink key={key} to={`.${route?.replace('/*', '')}`} className="brand-list-item">
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  brand_data={brand_data}
                />
              </NavLink>
            )
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            )
          }
        } else if (type === 'permiume' && agency?.purchased_product?.products.includes(key)) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                brand_data={brand_data}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <NavLink key={key} to={`.${route?.replace('/*', '')}`} className="brand-list-item">
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                brand_data={brand_data}
              />
            </NavLink>
          )
        } else if (type === 'title') {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          )
        } else if (type === 'divider') {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          )
        }
      }
      if (type === parent_path) {
        returnValue = (
          <NavLink key={key} to={`.${route?.replace('/*', '')}`} className="brand-list-item">
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              brand_data={brand_data}
            />
          </NavLink>
        )
      }

      return returnValue
    }
  )

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      brand_data={brand_data}
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {parent_path == 'settings' ? (
        <MDBox pt={2} pl={2}>
          <MDButton size="small" onClick={() => navigate('./dashboard')}>
            Go Back
          </MDButton>
        </MDBox>
      ) : (
        <MDBox />
      )}
      <MDBox pt={parent_path == 'settings' ? 1 : 3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center">
          <MDBox component="img" src={ghllogo} alt="logo" height="3rem" />
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} pb={0} mt="auto">
        {/* {agency.type == "free" ? <UpgradeModal /> : <></>} */}
      </MDBox>
      <MDBox p={2} mt={0}>
        <MDButton
          // component="a"
          // href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          // target="_blank"
          // rel="noreferrer"
          onClick={() => navigate(`./settings`)}
          variant="gradient"
          color={'info'}
          fullWidth
        >
          Settings
        </MDButton>
      </MDBox>
    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  brand_data: PropTypes.arrayOf(PropTypes.array).isRequired,
}

export default Sidenav
