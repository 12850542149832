// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import Swal from 'sweetalert2'
import { Icon } from '@mui/material'
import Category from '../Category/create'

export default function data() {
  const AppService = useAppServices()
  const [loader, setLoader] = useState(true)
  const [docs, setDocs] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [subcategories, setSubcategories] = useState({})
  const [prompts, setPrompts] = useState({})

  const [selected, setSelected] = useState()
  const handleOnClick = (item, idx) => {
    setOpen(idx)
    setSelected(item)
  }
  const handleDeleteCategory = async (id, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.docs_category.delete({
        query: `_id=${id}`,
        toaster: true,
      })
      if (response) {
        docs.splice(idx, 1)
        setDocs([...docs])
      }
    })
  }
  const getDocs_Cat = async () => {
    const { response } = await AppService.docs.get_filter_cat({ query: 'type=super-admin' })
    if (response) {
      setDocs(response.data.prompt_category)

      // Create a mapping of category_id to subcategories
      const subcategoryMapping = response.data.prompt_sub_category.reduce((acc, subcat) => {
        acc[subcat.category_id] = [...(acc[subcat.category_id] || []), subcat]
        return acc
      }, {})
      setSubcategories(subcategoryMapping)

      const promptsMapping = response.data.prompt.reduce((acc, prompt) => {
        acc[prompt.category] = [...(acc[prompt.category] || []), prompt]
        return acc
      }, {})
      setPrompts(promptsMapping)
    }
    setLoader(false)
  }

  const renderSubcategories = (categoryId) => {
    const categorySubcategories = subcategories[categoryId] || []
    return (
      <div className="mainBoxTd">
        {categorySubcategories.map((subcat) => (
          <MDTypography
            key={subcat._id}
            color="text"
            sx={{ fontSize: '16px', marginBottom: '4px' }}
          >
            * {subcat.name}
          </MDTypography>
        ))}
      </div>
    )
  }

  const renderPrompts = (subcategoryId) => {
    const subcategoryPrompts = prompts[subcategoryId] || []
    console.log(subcategoryPrompts)
    return (
      <div style={{ maxWidth: '400px' }}>
        {subcategoryPrompts.map((subcat) => (
          <MDTypography
            key={subcat._id}
            color="text"
            sx={{ fontSize: '16px', marginBottom: '4px' }}
          >
            * {subcat.title}
          </MDTypography>
        ))}
      </div>
    )
  }

  const onLoad = () => {
    getDocs_Cat()
  }
  useEffect(onLoad, [])

  const handleClose = () => {
    setOpen(false)
    getDocs_Cat()
  }

  return {
    columns: [
      { Header: '', accessor: 'order', width: '10%', align: 'left', verticalAlign: 'baseline' },
      { Header: 'name', accessor: 'name', align: 'left', width: '10%', verticalAlign: 'baseline' },
      {
        Header: 'Sub Category',
        accessor: 'subcat',
        align: 'left',
        width: '10%',
        verticalAlign: 'baseline',
      },
      {
        Header: 'Prompts',
        accessor: 'prompts',
        width: '25%',
        align: 'left',
        verticalAlign: 'baseline',
      },
      {
        Header: 'action',
        accessor: 'action',
        align: 'center',
        width: '10%',
        verticalAlign: 'baseline',
      },
    ],

    rows: docs.map((item, idx) => ({
      order: (
        <MDBox sx={{ cursor: 'pointer' }}>
          <Icon>menu</Icon>
        </MDBox>
      ),
      name: (
        <div style={{ maxWidth: '200px' }}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{ cursor: 'pointer', fontSize: '20px' }}
          >
            {item.name}
          </MDTypography>
        </div>
      ),
      subcat: <div>{renderSubcategories(item._id)}</div>,
      prompts: <div>{renderPrompts(item._id)}</div>,
      action: (
        <>
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDeleteCategory(item._id, idx)}
          >
            delete
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => navigate('./subcategory', { state: item })}
          >
            view
          </MDButton>
          {selected == item && (
            <Category category={selected} open={open === idx} onClose={handleClose} />
          )}
        </>
      ),
    })),
    getDocs_Cat,
  }
}
